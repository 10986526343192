import React, { useState } from 'react';
import Image, { ImageProps as NextImageProps } from 'next/image';
import * as AspectRatio from '@radix-ui/react-aspect-ratio';
import Skeleton from '@mui/material/Skeleton';
import { Box, BoxProps } from '@mui/material';

export interface ImageProps extends NextImageProps {
  boxProps?: BoxProps,
  ratio: number,
}

const CustomImage = ({ alt, src, ratio, boxProps }: ImageProps) => {
  const [isLoaded, setIsLoaded] = useState<boolean>(false);

  return (
    <Box {...boxProps} sx={{
      position: 'relative',
      width: 200,
    }}>
      <AspectRatio.Root ratio={ratio}>
        <Image
          layout={'fill'}
          alt={alt}
          src={src}
          onLoadingComplete={(e) => setIsLoaded(true)}
        />
        {!isLoaded && (
          <Skeleton sx={{
            height: '100%',
          }} />
          )}
      </AspectRatio.Root>
    </Box>
  );
};

export default CustomImage;
