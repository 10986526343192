import React from 'react';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';


const Footer = (): JSX.Element => {
  const theme = useTheme();
  const { mode } = theme.palette;

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Box
          display={'flex'}
          justifyContent={'space-between'}
          alignItems={'center'}
          width={1}
          flexDirection={{ xs: 'column', sm: 'row' }}
        >
          <Box
            display={'flex'}
            component='a'
            href='/'
            title='KriegCloud'
            width={80}
          >
            <Box
              component={'img'}
              alt={'illustration'}
              src={
                mode === 'light'
                  ? '/logo-black.svg'
                  : '/logo-white.svg'
              }
              sx={{
                height: '100%',
                width: '200px'
              }}
              height={1}
              width={1}
            />
          </Box>
          <Box display='flex' flexWrap={'wrap'} alignItems={'center'}>
            <Box marginTop={1} marginRight={2}>
              <Link
                underline='none'
                component='a'
                href='/'
                color='text.primary'
                variant={'subtitle2'}
              >
                Home
              </Link>
            </Box>
          </Box>
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Typography
          align={'center'}
          variant={'body1'}
          color='text.secondary'
          gutterBottom
        >
          &copy; KriegCloud. 2022. All rights reserved
        </Typography>
        <Typography
          align={'center'}
          variant={'body2'}
          color='text.secondary'
          component={'p'}
        >
          When you visit or interact with our sites, services or tools, we or
          our authorised service providers may use cookies for storing
          information to help provide you with a better, faster and safer
          experience and for marketing purposes.
        </Typography>
      </Grid>
    </Grid>
  );
};

export default Footer;
