import Box from "@mui/material/Box";
interface ToggleButtonProps {
  onChange: () => void;
  mode: 'light' | 'dark';
}

const ToggleButton = (
  {
    onChange,
    mode,
  }: ToggleButtonProps) => {

  return (
    <Box
      sx={{
        padding: '8px',
        alignItems: 'center',
        display: 'flex'
      }}
    >
      <input
        type='checkbox'
        checked={mode === 'dark'}
        aria-label={'dark, light mode toggle'}
        id='toggle'
        className={'toggle'}
        onChange={onChange} />
    </Box>
  );
};

export default ToggleButton;
