import React from 'react';
import { useTheme } from '@mui/material/styles';
import paletteTypes from '../../../theme/paletteTypes';
import Box from '@mui/material/Box';
import { colors } from '../../../utils';
import ToggleButton from './ToggleButton';

const ThemeModeToggler = (): JSX.Element => {
  const theme = useTheme();
  const { themeToggler, setThemePalette, paletteType } = theme;
  const { mode } = theme.palette;

  return (
    <Box display='flex' alignItems={'center'}>
      <Box
        display={'flex'}
        padding={1}
        borderRadius={8}
        bgcolor={theme.palette.alternate.main}
      >
        {paletteTypes.map((item: string, i) => (
          <Box
            key={item}
            bgcolor={colors[item][700]}
            width={20}
            height={20}
            borderRadius={'100%'}
            marginRight={i === paletteTypes.length - 1 ? 0 : 1}
            display={'flex'}
            justifyContent={'center'}
            alignItems={'center'}
            sx={{ cursor: 'pointer' }}
            onClick={() => setThemePalette(item)}
          >
            {paletteType === item && (
              <svg
                width={12}
                height={12}
                xmlns='http://www.w3.org/2000/svg'
                viewBox='0 0 20 20'
                fill='currentColor'
              >
                <path
                  fillRule='evenodd'
                  d='M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z'
                  clipRule='evenodd'
                />
              </svg>
            )}
          </Box>
        ))}
      </Box>
      <Box>
        <ToggleButton
          onChange={() => themeToggler()}
          mode={mode}
        />
      </Box>
    </Box>
  );
};

export default ThemeModeToggler;
