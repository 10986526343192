/* eslint-disable react/no-unescaped-entities */
import React, { useState } from 'react';
import CountUp from 'react-countup';
import VisibilitySensor from 'react-visibility-sensor';
import { useTheme } from '@mui/material/styles';
import Card from '@mui/material/Card';
import Box from '@mui/material/Box';
import useMediaQuery from '@mui/material/useMediaQuery';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';

import Image from 'next/image';


const CustomCardMedia = ({ src, alt, ...rest }: any): JSX.Element => {
  return (
    <Box sx={{
      position: 'relative',
      width: '100%',
      height: '100%',
      'img': {
        objectFit: 'cover',
      }
    }}>
      <Image
        src={src}
        image={src}
        alt={alt}
        {...rest}
      />
    </Box>

  );
};

const mock = [
  {
    title: 300,
    subtitle:
      '300 + component compositions, which will help you to build any page easily.',
    suffix: '+',
  },
  {
    title: 45,
    subtitle:
      '45 + landing and supported pages to Build a professional website.',
    suffix: '+',
  },
  {
    title: 99,
    subtitle: '99% of our customers rated 5-star our themes over 5 years.',
    suffix: '%',
  },
];

const Features = (): JSX.Element => {
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });

  const [viewPortEntered, setViewPortEntered] = useState(false);
  const setViewPortVisibility = (isVisible: boolean) => {
    if (viewPortEntered) {
      return;
    }

    setViewPortEntered(isVisible);
  };

  return (
    <Box>
      <Grid container spacing={4} direction={isMd ? 'row' : 'column-reverse'}>
        <Grid item xs={12} md={6} data-aos={isMd ? 'fade-right' : 'fade-up'}>
          <Box marginBottom={4}>
            <Typography sx={{ fontWeight: 700 }} variant={'h4'} gutterBottom>
              The powerful and flexible theme for all kinds of businesses
            </Typography>
            <Typography variant={'h6'} component={'p'} color={'text.secondary'}>
              Whether you're creating a subscription service, an on-demand
              marketplace, an e-commerce store, or a portfolio showcase,
              KriegCloud helps you create the best possible product for your
              users.
            </Typography>
          </Box>
          <Grid container spacing={2}>
            {mock.map((item, i) => (
              <Grid key={i} item xs={12} md={4}>
                <Typography variant='h4' color={'primary'} gutterBottom>
                  <VisibilitySensor
                    onChange={(isVisible: boolean) => setViewPortVisibility(isVisible)}
                    delayedCall
                  >
                    <CountUp
                      duration={2}
                      end={viewPortEntered ? item.title : 0}
                      start={0}
                      suffix={item.suffix}
                    />
                  </VisibilitySensor>
                </Typography>
                <Typography color='text.secondary' component='p'>
                  {item.subtitle}
                </Typography>
              </Grid>
            ))}
          </Grid>
        </Grid>
        <Grid
          item
          container
          justifyContent='center'
          alignItems='center'
          xs={12}
          md={6}
          sx={{
            display: { xs: 'none', md: 'flex' },
          }}
        >
          <Box component={Card} boxShadow={4} height={1} width={1}>
            <CustomCardMedia
              alt={'background'}
              src='https://assets.krieg.cloud/backgrounds/img4.webp'
              image={'https://assets.krieg.cloud/backgrounds/img4.webp'}
              layout={'fill'}
            />
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Features;
