/* eslint-disable react/prop-types */
import React from 'react';
import Head from 'next/head';
import { Page } from 'ui';
import type { AppProps as NextAppProps } from 'next/app';

import 'react-lazy-load-image-component/src/effects/blur.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import 'react-image-lightbox/style.css';
import 'aos/dist/aos.css';
import '../scss/main.scss';

interface MyAppProps {
  Component: any,
  pageProps: NextAppProps
}

export default function App({ Component, pageProps }: MyAppProps): JSX.Element {
  return (
    <React.Fragment>
      <Head>
         <meta name="viewport" content="width=device-width, initial-scale=1" />
        <title>Krieg Cloud | Commence war on Big Tech.</title>
      </Head>
      <Page>
        <Component {...pageProps} />
      </Page>
    </React.Fragment>
  );
}
