import React from 'react';
import { Main } from '../../../templates';
import { Container } from '../../../atoms';

const ServerError = (): JSX.Element => {
  return (
    <Main>
      <Container>ServerError</Container>
    </Main>
  );
};

export default ServerError;
