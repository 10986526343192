import React from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { alpha, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';

const data = [
  {
    title: 'Landing pages',
    subtitle:
      'From rich starting points to simple single pagers, anything is possible.',
    pages: [
      {
        title: 'Marketing',
        description: 'the main page',
        href: '/',
        cover:
          'https://assets.krieg.cloud/sshots/coworking-light.webp',
        coverDark:
          'https://assets.krieg.cloud/sshots/coworking-dark.webp',
      },
      {
        title: 'Coworking',
        href: '/coworking',
        cover:
          'https://assets.krieg.cloud/sshots/coworking-light.webp',
        coverDark:
          'https://assets.krieg.cloud/sshots/coworking-dark.webp',
      },
      {
        title: 'Rental',
        href: '/rental',
        cover:
          'https://assets.krieg.cloud/sshots/rental-light.webp',
        coverDark:
          'https://assets.krieg.cloud/sshots/rental-dark.webp',
      },
      {
        title: 'Job Listing',
        href: '/job-listing',
        cover:
          'https://assets.krieg.cloud/sshots/joblisting-light.webp',
        coverDark:
          'https://assets.krieg.cloud/sshots/joblisting-dark.webp',
      },
      {
        title: 'E-Learning',
        href: '/e-learning',
        cover:
          'https://assets.krieg.cloud/sshots/e-learning-light.webp',
        coverDark:
          'https://assets.krieg.cloud/sshots/e-learning-dark.webp',
      },
      {
        title: 'E-commerce',
        href: '/e-commerce',
        cover:
          'https://assets.krieg.cloud/sshots/e-commerce-light.webp',
        coverDark:
          'https://assets.krieg.cloud/sshots/e-commerce-dark.webp',
      },
      {
        title: 'Expo',
        href: '/expo',
        cover:
          'https://assets.krieg.cloud/sshots/expo-light.webp',
        coverDark:
          'https://assets.krieg.cloud/sshots/expo-dark.webp',
      },
      {
        title: 'Desktop App',
        href: '/desktop-app',
        cover:
          'https://assets.krieg.cloud/sshots/desktop-app-light.webp',
        coverDark:
          'https://assets.krieg.cloud/sshots/desktop-app-dark.webp',
      },
      {
        title: 'Mobile App',
        href: '/mobile-app',
        cover:
          'https://assets.krieg.cloud/sshots/mobile-app-light.webp',
        coverDark:
          'https://assets.krieg.cloud/sshots/mobile-app-dark.webp',
      },
      {
        title: 'Basic',
        href: '/web-basic',
        cover:
          'https://assets.krieg.cloud/sshots/web-basic-light.webp',
        coverDark:
          'https://assets.krieg.cloud/sshots/web-basic-dark.webp',
      },
      {
        title: 'Service',
        href: '/service',
        cover:
          'https://assets.krieg.cloud/sshots/service-light.webp',
        coverDark:
          'https://assets.krieg.cloud/sshots/service-dark.webp',
      },
      {
        title: 'Startup',
        href: '/startup',
        cover:
          'https://assets.krieg.cloud/sshots/startup-light.webp',
        coverDark:
          'https://assets.krieg.cloud/sshots/startup-dark.webp',
      },
      {
        title: 'Enterprise',
        href: '/enterprise',
        cover:
          'https://assets.krieg.cloud/sshots/enterprise-light.webp',
        coverDark:
          'https://assets.krieg.cloud/sshots/enterprise-dark.webp',
      },
      {
        title: 'Cloud Hosting',
        href: '/cloud-hosting',
        cover:
          'https://assets.krieg.cloud/sshots/cloud-hosting-light.webp',
        coverDark:
          'https://assets.krieg.cloud/sshots/cloud-hosting-dark.webp',
      },
      {
        title: 'Agency',
        href: '/agency',
        cover:
          'https://assets.krieg.cloud/sshots/agency-light.webp',
        coverDark:
          'https://assets.krieg.cloud/sshots/agency-dark.webp',
      },
      {
        title: 'Design Company',
        href: '/design-company',
        cover:
          'https://assets.krieg.cloud/sshots/design-company-light.webp',
        coverDark:
          'https://assets.krieg.cloud/sshots/design-company-dark.webp',
      },
      {
        title: 'Logistics',
        href: '/logistics',
        cover:
          'https://assets.krieg.cloud/sshots/logistics-company-light.webp',
        coverDark:
          'https://assets.krieg.cloud/sshots/logistics-company-dark.webp',
      },
    ],
  },
  {
    title: 'Secondary pages',
    subtitle: 'These are the supporting pages to supplement the main landings.',
    pages: [
      {
        title: 'Career Listing',
        href: '/career-listing',
        cover:
          'https://assets.krieg.cloud/sshots/joblisting-light.webp',
        coverDark:
          'https://assets.krieg.cloud/sshots/joblisting-dark.webp',
      },
      {
        title: 'Career Listing Minimal',
        href: '/career-listing-minimal',
        cover:
          'https://assets.krieg.cloud/sshots/career-listing-minimal-light.webp',
        coverDark:
          'https://assets.krieg.cloud/sshots/career-listing-minimal-dark.webp',
      },
      {
        title: 'Job Opening',
        href: '/career-opening',
        cover:
          'https://assets.krieg.cloud/sshots/career-opening-light.webp',
        coverDark:
          'https://assets.krieg.cloud/sshots/career-opening-dark.webp',
      },
      {
        title: 'Help Center Overview',
        href: '/help-center',
        cover:
          'https://assets.krieg.cloud/sshots/help-center-light.webp',
        coverDark:
          'https://assets.krieg.cloud/sshots/help-center-dark.webp',
      },
      {
        title: 'Help Center Article',
        href: '/help-center-article',
        cover:
          'https://assets.krieg.cloud/sshots/help-center-article-light.webp',
        coverDark:
          'https://assets.krieg.cloud/sshots/help-center-article-dark.webp',
      },
      {
        title: 'Company About',
        href: '/about',
        cover:
          'https://assets.krieg.cloud/sshots/about-light.webp',
        coverDark:
          'https://assets.krieg.cloud/sshots/about-dark.webp',
      },
      {
        title: 'Company About (Cover)',
        href: '/about-side-cover',
        cover:
          'https://assets.krieg.cloud/sshots/about-side-cover-light.webp',
        coverDark:
          'https://assets.krieg.cloud/sshots/about-side-cover-dark.webp',
      },
      {
        title: 'Company Pricing',
        href: '/pricing',
        cover:
          'https://assets.krieg.cloud/sshots/pricing-light.webp',
        coverDark:
          'https://assets.krieg.cloud/sshots/pricing-dark.webp',
      },
      {
        title: 'Company Terms',
        href: '/company-terms',
        cover:
          'https://assets.krieg.cloud/sshots/company-terms-light.webp',
        coverDark:
          'https://assets.krieg.cloud/sshots/company-terms-dark.webp',
      },
      {
        title: 'Contact Reach View',
        href: '/contact-page',
        cover:
          'https://assets.krieg.cloud/sshots/contact-page-light.webp',
        coverDark:
          'https://assets.krieg.cloud/sshots/contact-page-dark.webp',
      },
      {
        title: 'Contact Sidebar Map',
        href: '/contact-sidebar-map',
        cover:
          'https://assets.krieg.cloud/sshots/contact-sidebar-map-light.webp',
        coverDark:
          'https://assets.krieg.cloud/sshots/contact-sidebar-map-dark.webp',
      },
      {
        title: 'Contact Cover',
        href: '/contact-page-cover',
        cover:
          'https://assets.krieg.cloud/sshots/contact-page-cover-light.webp',
        coverDark:
          'https://assets.krieg.cloud/sshots/contact-page-cover-dark.webp',
      },
      {
        title: 'Blog Newsroom',
        href: '/blog-newsroom',
        cover:
          'https://assets.krieg.cloud/sshots/blog-newsroom-light.webp',
        coverDark:
          'https://assets.krieg.cloud/sshots/blog-newsroom-dark.webp',
      },
      {
        title: 'Blog Reach View',
        href: '/blog-reach-view',
        cover:
          'https://assets.krieg.cloud/sshots/blog-reach-view-light.webp',
        coverDark:
          'https://assets.krieg.cloud/sshots/blog-reach-view-dark.webp',
      },
      {
        title: 'Blog Search',
        href: '/blog-search',
        cover:
          'https://assets.krieg.cloud/sshots/blog-search-light.webp',
        coverDark:
          'https://assets.krieg.cloud/sshots/blog-search-dark.webp',
      },
      {
        title: 'Blog Article',
        href: '/blog-article',
        cover:
          'https://assets.krieg.cloud/sshots/blog-article-light.webp',
        coverDark:
          'https://assets.krieg.cloud/sshots/blog-article-dark.webp',
      },
      {
        title: 'Portfolio Basic',
        href: '/portfolio-page',
        cover:
          'https://assets.krieg.cloud/sshots/portfolio-page-light.webp',
        coverDark:
          'https://assets.krieg.cloud/sshots/portfolio-page-dark.webp',
      },
      {
        title: 'Portfolio Masonry',
        href: '/portfolio-masonry',
        cover:
          'https://assets.krieg.cloud/sshots/portfolio-masonry-light.webp',
        coverDark:
          'https://assets.krieg.cloud/sshots/portfolio-masonry-dark.webp',
      },
      {
        title: 'Portfolio Grid View',
        href: '/portfolio-grid',
        cover:
          'https://assets.krieg.cloud/sshots/portfolio-grid-light.webp',
        coverDark:
          'https://assets.krieg.cloud/sshots/portfolio-grid-dark.webp',
      },
      {
        title: 'Portfolio Parallax Effect',
        href: '/agency',
        cover:
          'https://assets.krieg.cloud/sshots/agency-light.webp',
        coverDark:
          'https://assets.krieg.cloud/sshots/agency-dark.webp',
      },
    ],
  },
  {
    title: 'Account pages',
    subtitle: 'These pages are used for basic authentication management.',
    pages: [
      {
        title: 'Account General',
        href: '/account-general',
        cover:
          'https://assets.krieg.cloud/sshots/account-general-light.webp',
        coverDark:
          'https://assets.krieg.cloud/sshots/account-general-dark.webp',
      },
      {
        title: 'Account Security',
        href: '/account-security',
        cover:
          'https://assets.krieg.cloud/sshots/account-security-light.webp',
        coverDark:
          'https://assets.krieg.cloud/sshots/account-security-dark.webp',
      },
      {
        title: 'Account Notifications',
        href: '/account-notifications',
        cover:
          'https://assets.krieg.cloud/sshots/account-notifications-light.webp',
        coverDark:
          'https://assets.krieg.cloud/sshots/account-notifications-dark.webp',
      },
      {
        title: 'Account Billing',
        href: '/account-billing',
        cover:
          'https://assets.krieg.cloud/sshots/account-billing-light.webp',
        coverDark:
          'https://assets.krieg.cloud/sshots/account-billing-dark.webp',
      },
      {
        title: 'Signup Simple',
        href: '/signup-simple',
        cover:
          'https://assets.krieg.cloud/sshots/signup-simple-light.webp',
        coverDark:
          'https://assets.krieg.cloud/sshots/signup-simple-dark.webp',
      },
      {
        title: 'Signup Cover',
        href: '/signup-cover',
        cover:
          'https://assets.krieg.cloud/sshots/signup-cover-light.webp',
        coverDark:
          'https://assets.krieg.cloud/sshots/signup-cover-dark.webp',
      },
      {
        title: 'Signin Simple',
        href: '/signin-simple',
        cover:
          'https://assets.krieg.cloud/sshots/signin-simple-light.webp',
        coverDark:
          'https://assets.krieg.cloud/sshots/signin-simple-dark.webp',
      },
      {
        title: 'Signin Cover',
        href: '/signin-cover',
        cover:
          'https://assets.krieg.cloud/sshots/signin-cover-light.webp',
        coverDark:
          'https://assets.krieg.cloud/sshots/signin-cover-dark.webp',
      },
      {
        title: 'Password Reset Simple',
        href: '/password-reset-simple',
        cover:
          'https://assets.krieg.cloud/sshots/password-reset-simple-light.webp',
        coverDark:
          'https://assets.krieg.cloud/sshots/password-reset-simple-dark.webp',
      },
      {
        title: 'Password Reset Cover',
        href: '/password-reset-cover',
        cover:
          'https://assets.krieg.cloud/sshots/password-reset-cover-light.webp',
        coverDark:
          'https://assets.krieg.cloud/sshots/password-reset-cover-dark.webp',
      },
      {
        title: 'Error Simple',
        href: '/not-found',
        cover:
          'https://assets.krieg.cloud/sshots/not-found-light.webp',
        coverDark:
          'https://assets.krieg.cloud/sshots/not-found-dark.webp',
      },
      {
        title: 'Error Cover',
        href: '/not-found-cover',
        cover:
          'https://assets.krieg.cloud/sshots/not-found-cover-light.webp',
        coverDark:
          'https://assets.krieg.cloud/sshots/not-found-cover-dark.webp',
      },
    ],
  },
];

const PageReferences = (): JSX.Element => {
  const theme = useTheme();
  return (
    <Box>
      {data.map((item, i) => (
        <Box
          key={i}
          sx={{
            marginBottom: 4,
            paddingBottom: 4,
            borderBottom: `1px solid ${alpha(theme.palette.divider, 0.1)}`,
          }}
        >
          <Box marginBottom={4}>
            <Typography variant={'h4'} gutterBottom>
              {item.title}
            </Typography>
            <Typography color={'text.secondary'} component={'p'}>
              {item.subtitle}
            </Typography>
          </Box>
          <Grid container spacing={4}>
            {item.pages.map((p, j) => (
              <Grid
                key={j}
                item
                xs={12}
                sm={6}
                md={4}
                component={'a'}
                href={p.href}
                sx={{ textDecoration: 'none !important' }}
                data-aos={'fade-up'}
                data-aos-delay={(i + j) * 200}
                data-aos-offset={100}
                data-aos-duration={600}
              >
                <Box
                  padding={1}
                  bgcolor={'background.paper'}
                  borderRadius={2}
                  overflow={'hidden'}
                  boxShadow={3}
                  marginBottom={2}
                >
                  <Box
                    component={LazyLoadImage}
                    effect="blur"
                    src={theme.palette.mode === 'dark' ? p.coverDark : p.cover}
                    alt={p.title}
                    height={1}
                    width={1}
                    sx={{
                      transition: 'opacity, transform ease 0.3s !important',
                      '&:hover': {
                        transform: 'scale(1.2)',
                      },
                    }}
                  />
                </Box>
                <Box display={'flex'} justifyContent={'center'}>
                  <Typography
                    variant={'subtitle1'}
                    fontWeight={700}
                    color={'text.primary'}
                  >
                    {p.title}
                  </Typography>
                  {p.description ? (
                    <Typography
                      variant={'subtitle1'}
                      color={'text.primary'}
                      sx={{ marginLeft: 0.5 }}
                    >
                      ({p.description})
                    </Typography>
                  ) : null}
                </Box>
              </Grid>
            ))}
          </Grid>
        </Box>
      ))}
      <Box marginTop={4}>
        <Typography
          variant={'h6'}
          component={'p'}
          gutterBottom
          align={'center'}
        >
          ...and more soon.
        </Typography>
        <Typography
          variant={'h6'}
          component={'p'}
          color={'text.secondary'}
          align={'center'}
        >
          KriegCloud is an ever-growing system of composable components. We’re
          consistently adding landing examples, improving components, and
          gathering feedback from customers to make their experience better.
        </Typography>
      </Box>
    </Box>
  );
};

export default PageReferences;
